import richText from "utilities/richText"
import Image from "components/Image"
import JotFormEmbed from "components/JotFormEmbed"
import { getStoryblokLink } from "utilities/getStoryblokLink"

export default function GlobalForm({ content, image, form_url }) {
  return (
    <section className="bg-primary mix-blend-multiply px-5 overflow-hidden relative" data-pagefind-ignore="all">
      <div className="relative global-form form-brackets form-brackets-primary py-14 lg:pt-14 lg:pb-10 max-w-5xl mx-auto">
        <div className="global-form-content text-white lg:text-center flex flex-col items-center gap-4 lg:max-w-2xl mx-auto">
          {richText(content)}
        </div>
        <div className="global-form-image relative">
          <Image
            src={image?.filename}
            alt={image?.alt}
            width={463}
            height={287}
            className="lg:absolute lg:h-full w-full lg:inset-0 object-cover max-h-[465px]"
            sizes="(max-width: 1024px) 95vw, 25vw"
          />
        </div>
        <JotFormEmbed formURL={getStoryblokLink(form_url)} />
      </div>
    </section>
  )
}
