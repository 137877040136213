import { useState, useEffect } from "react"
import Link from "next/link"
import { useRouter } from "next/router"
import Image from "next/image"
import cn from "classnames"
import CallToAction from "components/CallToAction"
import SubMenu from "components/Header/SubMenu"
import SearchBar from "components/SearchBar"
import MenuButton from "public/assets/hamburger-menu.svg"
import Logo from "public/assets/logo.svg"
import getTelLink from "utilities/getTelLink"

export default function Header({ header: { mainMenu, sideMenu }, phoneNumbers }) {
  const [menuVisibility, setMenuVisibility] = useState(false)
  const [submenuOpen, setSubmenuOpen] = useState("")
  const [openSecondLevelMenu, setOpenSecondLevelMenu] = useState("")
  const [openThirdLevelMenu, setOpenThirdLevelSubmenu] = useState("")

  const router = useRouter()

  // close the menu when a link is clicked
  useEffect(() => {
    const handleRouteChange = () => {
      setMenuVisibility(false)
      setSubmenuOpen("")
      setOpenSecondLevelMenu("")
      setOpenThirdLevelSubmenu("")
    }
    router.events.on("routeChangeComplete", handleRouteChange)
    router.events.on("hashChangeComplete", handleRouteChange)

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
      router.events.off("hashChangeComplete", handleRouteChange)
    }
  }, [router.events])

  // disable scroll when mobile menu is open
  useEffect(() => {
    if (menuVisibility) document.querySelector("html").style.overflow = "hidden"
    if (!menuVisibility) document.querySelector("html").style.overflow = ""
  }, [menuVisibility])

  useEffect(() => setOpenThirdLevelSubmenu(""), [openSecondLevelMenu])

  function updateSubmenu(depth, items, label) {
    if (depth === 1 && items && items.length > 0) {
      setOpenSecondLevelMenu((prev) => (prev !== label ? label : ""))
    }
    if (depth === 2 && items && items.length > 0) {
      setOpenThirdLevelSubmenu((prev) => (prev !== label ? label : ""))
    }
  }

  return (
    <header className="overflow-hidden relative z-50 bg-primary">
      <div
        className={cn("px-5 pt-6 lg:pb-6 relative z-30 overflow-y-auto", {
          "h-[100dvh] min-h-[600px]": menuVisibility,
          "pb-6": !menuVisibility,
        })}
      >
        <div className="max-w-screen-xl mx-auto flex items-center justify-between gap-3 xl:gap-12">
          <Link href="/" title="The Husband and Wife Law Team">
            <Logo className="max-w-[269px] sm:max-w-none" />
          </Link>
          <div className="flex items-center gap-6">
            <div
              className={cn(
                "hidden xl:block relative before:content-[''] before:bg-contain before:bg-no-repeat before:bg-[url('public/assets/curly-bracket.svg')] before:absolute before:w-3 before:h-12 before:-left-5 before:top-0 after:content-[''] after:bg-contain after:bg-no-repeat after:bg-[url('public/assets/curly-bracket.svg')] after:absolute after:rotate-180 after:w-3 after:h-12 after:top-0 after:-right-4 mr-8 transition-all duration-300",
                {
                  "xl:opacity-100": !menuVisibility,
                  "xl:opacity-0": menuVisibility,
                },
              )}
            >
              <h2 className="text-m1 font-semibold text-white text-center">Contact us 24/7 for a FREE Consultation</h2>
              <div className="flex gap-5 justify-center">
                <CallToAction href={getTelLink(phoneNumbers?.Main)} style="secondary" className="whitespace-nowrap">
                  {phoneNumbers?.Main}
                </CallToAction>
                <CallToAction href="/free-case-evaluation" style="secondary" className="whitespace-nowrap">
                  Free Case Review
                </CallToAction>
              </div>
            </div>
            <div className="hidden xl:block">
              <SearchBar />
            </div>

            <button
              aria-label={menuVisibility ? "close menu" : "open menu"}
              onClick={() => setMenuVisibility((prev) => !prev)}
              className="hamburger-btn text-base text-white flex flex-col justify-center w-12 text-centers"
            >
              <MenuButton className={cn("cursor-pointer mx-auto", { open: menuVisibility })} />
              <span className="text-center mx-auto">{menuVisibility ? "Exit" : "Menu"}</span>
            </button>
          </div>
        </div>
        <div className="flex gap-4 justify-between pt-3 xl:hidden">
          <p className="text-m1 text-white font-primary whitespace-nowrap pb-0">We’re Here 24/7</p>
          <div className="flex gap-4">
            <CallToAction href={getTelLink(phoneNumbers?.Main)} style="secondary" className="whitespace-nowrap">
              Call Now
            </CallToAction>
            <CallToAction href="#" style="secondary" className="whitespace-nowrap">
              Live Chat
            </CallToAction>
          </div>
        </div>
        <div
          className={cn("py-8 xl:p-0 z-30 max-w-screen-xl mx-auto", {
            hidden: !menuVisibility,
          })}
        >
          <nav aria-label="Main" className="main-menu">
            <div className="w-full mb-4 xl:hidden mx-auto">
              <SearchBar />
            </div>
            <SubMenu
              {...{
                menuItems: mainMenu,
                currentPath: router.asPath,
                submenuOpen,
                openThirdLevelMenu,
                setOpenThirdLevelSubmenu,
                openSecondLevelMenu,
                setOpenSecondLevelMenu,
                updateSubmenu,
                menuVisibility,
              }}
            />
            <SubMenu
              {...{
                menuItems: sideMenu,
                currentPath: router.asPath,
                submenuOpen,
                openThirdLevelMenu,
                setOpenThirdLevelSubmenu,
                openSecondLevelMenu,
                setOpenSecondLevelMenu,
                updateSubmenu,
                menuVisibility,
              }}
            />
          </nav>
        </div>
      </div>
      <Image
        aria-hidden
        src="/assets/header-background.webp"
        alt=""
        sizes="100vw"
        fill
        className="header-image-overlay z-0 h-[100dvh] object-cover object-left-top"
      />
    </header>
  )
}
