import { Fragment } from "react"
import Link from "next/link"
import cn from "classnames"
import { getStoryblokLink } from "utilities/getStoryblokLink"
import getTarget from "utilities/getTarget"

export default function SubMenu({
  menuItems,
  currentPath,
  menuVisibility,
  submenuOpen,
  openSecondLevelMenu,
  openThirdLevelMenu,
  updateSubmenu,
  depth = 1,
}) {
  return depth <= 3 && menuItems && menuItems.length > 0 ? (
    <ul>
      {menuItems.map((item) => {
        const href = getStoryblokLink(item.link)
        return (
          <Fragment key={`menu-item-${item._uid}`}>
            <li
              className={cn({
                "open-whole-submenu": submenuOpen === item.label && !menuVisibility && depth === 1,
                "closed-whole-submenu": submenuOpen !== item.label && !menuVisibility && depth === 1,
                "closed-second-submenu": openSecondLevelMenu !== item.label && menuVisibility && depth === 1,
                "closed-third-submenu": openThirdLevelMenu !== item.label && menuVisibility && depth === 2,
                "open-second-submenu": openSecondLevelMenu === item.label && menuVisibility && depth === 1,
                "open-third-submenu": openThirdLevelMenu === item.label && depth === 2,
                "side-menu-item": item.isSideMenu,
              })}
              onClick={(e) => {
                if (window.innerWidth >= 1280 && item.isSideMenu) return
                e.stopPropagation()
                updateSubmenu(depth, item.nested_menu_items, item.label)
              }}
              onKeyDown={(e) => {
                if (window.innerWidth >= 1280 && item.isSideMenu) return
                e.stopPropagation()
                if (e.code === "Enter") {
                  updateSubmenu(depth, item.nested_menu_items, item.label)
                }
              }}
            >
              {href ? (
                <Link
                  href={href}
                  className={cn("cursor-pointer", {
                    "current-page": currentPath === href,
                  })}
                  target={getTarget(href)}
                >
                  {item.label}
                </Link>
              ) : (
                <div className="nonlinked" tabIndex="0">
                  {item.label}
                </div>
              )}
              {depth === 1 && item?.nested_menu_items && item.nested_menu_items.length > 0 ? (
                <SubMenu
                  {...{
                    openThirdLevelMenu,
                    updateSubmenu,
                    submenuOpen,
                    menuVisibility,
                    openSecondLevelMenu,
                    menuItems: item.nested_menu_items,
                    depth: depth + 1,
                  }}
                />
              ) : null}
            </li>
            {depth === 2 && item?.nested_menu_items && item.nested_menu_items.length > 0 ? (
              <div className="third-level-items">
                <SubMenu
                  {...{
                    openThirdLevelMenu,
                    updateSubmenu,
                    submenuOpen,
                    menuVisibility,
                    openSecondLevelMenu,
                    menuItems: item.nested_menu_items,
                    depth: depth + 1,
                  }}
                />
              </div>
            ) : null}
          </Fragment>
        )
      })}
    </ul>
  ) : null
}
