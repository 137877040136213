import { useState, useEffect, useRef, useCallback } from "react"
import Link from "next/link"
import classNames from "classnames"
import { useRouter } from "next/router.js"
import CallToAction from "./CallToAction.jsx"

export default function SearchBar() {
  const router = useRouter()

  const searchInput = useRef(null)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const [query, setQuery] = useState("")
  const [results, setResults] = useState([])
  const maxResultsToDisplay = 3
  const resultsToDisplay = results.slice(0, maxResultsToDisplay)

  // Close and reset the component to initial state
  const closeSearchMenu = () => {
    setOpen(false)
    setResults([])
    setLoading(false)
    setQuery("")
  }

  // close the menu when a link is clicked
  useEffect(() => {
    router.events.on("routeChangeComplete", closeSearchMenu)
    router.events.on("hashChangeComplete", closeSearchMenu)

    return () => {
      router.events.off("routeChangeComplete", closeSearchMenu)
      router.events.off("hashChangeComplete", closeSearchMenu)
    }
  }, [router.events])

  // Trap focus to the search pane when the search pane is active
  const focusRef = useCallback(
    (modal) => {
      // Hack, but there should only ever be one of these
      const searchInput = document.querySelector("#search-query")
      if (open && modal !== null && searchInput) {
        searchInput.focus()

        modal.addEventListener("focusout", (e) => {
          if (!modal.contains(e.relatedTarget) && searchInput) {
            searchInput.focus()
          }
        })
      }
    },
    [open],
  )

  // Enable keyboard control
  useEffect(() => {
    const keyupListener = document.addEventListener(
      "keydown",
      (e) => {
        const modifierKey = e.ctrlKey || e.metaKey
        const k = e.key === "k"

        const shouldOpenSearch = modifierKey && k
        const shouldCloseSearch = e.key === "Escape"

        if (shouldOpenSearch) {
          setOpen(true)
        }

        if (shouldCloseSearch) {
          closeSearchMenu()
        }

        return () => {
          document.removeEventListener(keyupListener)
        }
      },
      false,
    )
  }, [])

  async function loadPagefind() {
    if (typeof window.pagefind === "undefined") {
      try {
        /**
         * This is very silly. This path is relative to the current directory.
         * It translates to the bundled output path for this file. This is a terrible, shameful practice.
         * But it works in development and production whereas other methods don't. ¯\_(ツ)_/¯
         * ../../../../../pagefind/pagefind.js => /next/static/chunks/pagefind/pagefind.js
         */
        window.pagefind = await import(
          // @ts-expect-error pagefind.js generated after build
          /* webpackIgnore: true */ "../../../../pagefind/pagefind.js"
        )
        await window.pagefind.options({
          excerptLength: 25,
          ranking: {
            pageLength: 0.5,
            termFrequency: 0.2,
            termSimilarity: 1.0,
            termSaturation: 1.0,
          },
        })
        setLoading(false)
      } catch (e) {
        console.error(`Error loading Pagefind: ${e}`)
        window.pagefind = { search: () => ({ results: [] }) }
      }
    }
  }

  const hideWhenInactive = {
    "hidden opacity-0": !open,
  }

  return (
    <div className="relative">
      <button
        className="fake-searchbar-btn text-left pl-3 w-full xl:w-[207px] h-[39px]"
        aria-label="open search"
        onClick={async () => {
          await loadPagefind()
          setOpen(true)
        }}
      >
        <div
          aria-hidden
          className="searchbar rounded-sm py-2 pl-10 pr-0 font-secondary text-grey-charcoal
           bg-white hover:bg-grey-cool-tint relative"
        >
          Search our Site...
        </div>
      </button>

      <div
        onClick={closeSearchMenu}
        className={classNames(
          "overlay transition-all opacity-100 z-50 backdrop-blur-sm fixed inset-0 w-full h-full bg-black bg-opacity-50",
          hideWhenInactive,
        )}
      ></div>
      <div
        ref={focusRef}
        tabIndex={0}
        className={classNames(
          "fixed flex flex-col bg-highlight-tint rounded-sm z-50 px-5 py-8 w-[100vw] h-full lg:h-max max-h-[100dvh] lg:max-h-[calc(100dvh_-_theme(spacing.12))] lg:w-1/2 lg:max-w-screen-md top-0 left-0 lg:inset-x-0 mx-auto lg:mt-6",
          hideWhenInactive,
        )}
      >
        {open ? (
          <>
            <button className="absolute right-5 top-4" onClick={closeSearchMenu}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_4565_15470)">
                  <path
                    d="M23.2897 4.0987C24.2268 3.16164 24.2268 1.63986 23.2897 0.702796C22.3526 -0.234265 20.8309 -0.234265 19.8938 0.702796L12 8.60409L4.0987 0.710292C3.16164 -0.226769 1.63986 -0.226769 0.702796 0.710292C-0.234265 1.64735 -0.234265 3.16914 0.702796 4.1062L8.60409 12L0.710292 19.9013C-0.226769 20.8384 -0.226769 22.3601 0.710292 23.2972C1.64735 24.2343 3.16914 24.2343 4.1062 23.2972L12 15.3959L19.9013 23.2897C20.8384 24.2268 22.3601 24.2268 23.2972 23.2897C24.2343 22.3526 24.2343 20.8309 23.2972 19.8938L15.3959 12L23.2897 4.0987Z"
                    fill="#122448"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4565_15470">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
            <div className="sr-only">Press escape to close</div>
            <h3 className="mb-4">Search Our Site</h3>
            <form className="w-full relative" onSubmit={(e) => e.preventDefault()}>
              <div className="searchbar">
                <input
                  ref={searchInput}
                  autoFocus
                  type="text"
                  id="search-query"
                  name="search"
                  placeholder="Enter a search term"
                  className="rounded-sm py-2 pl-3 pr-0 placeholder-grey-charcoal"
                  value={query}
                  onChange={async (e) => {
                    e.preventDefault()
                    if (typeof window.pagefind !== "undefined") {
                      setLoading(true)
                      setQuery(e.target.value)
                      const search = await window.pagefind.debouncedSearch(e.target.value, {
                        sort: {
                          "weight[data-weight]": "desc",
                        },
                      })
                      if (search) {
                        setResults(search.results)
                      }

                      setLoading(false)
                    } else {
                      loadPagefind()
                    }
                  }}
                  maxLength={50}
                />
              </div>
              <input
                className={classNames(
                  "absolute p-1 right-10 cursor-pointer leading-none text-tertiary -translate-y-1/2 top-1/2",
                  {
                    hidden: !searchInput.current?.value,
                  },
                )}
                type="reset"
                disabled={loading}
                aria-hidden={!searchInput.current?.value}
                value="Clear"
                onClick={(e) => {
                  e.target.value = ""
                  e.target.disabled = true
                  setQuery("")
                  searchInput.current.focus()
                  setLoading(false)
                  setResults([])
                }}
                alt="Clear the search form"
              />
              <div
                aria-hidden
                title="loading search results..."
                className={classNames(
                  "loading-indicator font-secondary absolute text-tertiary font-bold right-3 top-[7.125px]",
                  {
                    "animate-spin": loading,
                    hidden: !loading,
                  },
                )}
              />
              {results.length && !loading ? (
                <p className="absolute search-results-count text-grey-charcoal mt-2 mb-0 pb-0 px-2">
                  Showing {resultsToDisplay.length < maxResultsToDisplay ? results.length : maxResultsToDisplay} of{" "}
                  {results.length} results for {query}
                </p>
              ) : null}

            </form>

            <p className="block search-results-count text-grey-cool mt-2 mb-0 pb-0 px-2">
              Showing {resultsToDisplay.length < maxResultsToDisplay ? results.length : maxResultsToDisplay} of{" "}
              {results.length} results for {query}
            </p>

            <div className="search-results max-h-[80%] lg:max-h-max overflow-y-auto w-full bg-highlight-tint gap-4 pb-4 px-2 lg:px-8">
              {!!results.length && resultsToDisplay.map((result) => <Result key={result.id} {...{ result }} />)}
            </div>
            {results.length && results.length > maxResultsToDisplay ? (
              <div className="mx-auto text-center mt-4">
                <CallToAction href={`/search?q=${encodeURIComponent(searchInput.current?.value)}`} style="ghost">
                  View All Results
                </CallToAction>
              </div>
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  )
}

function Result({ result }) {
  const [data, setData] = useState(null)

  useEffect(() => {
    async function fetchData() {
      const { url, ...restOfData } = await result.data()

      setData({ url: url.replace("/server/pages", "").replace(".html", ""), ...restOfData })
    }
    fetchData()
  }, [result])

  if (!data) return null

  return (
    <Link className="block my-3 py-3 px-2" href={data.url}>
      <h3 className="text-m2 text-blue-darkest max-w-2xl font-primary mb-3">{data.meta.title}</h3>
      <div className="result-content font-secondary italic" dangerouslySetInnerHTML={{ __html: data.excerpt }} />
    </Link>
  )
}
