import Logo from "public/assets/logo.svg"
import CallToAction from "components/CallToAction"
import getTelLink from "utilities/getTelLink"
import Link from "next/link"
import { getStoryblokLink } from "utilities/getStoryblokLink"
import getTarget from "utilities/getTarget"
import richText from "utilities/richText"
import Facebook from "public/assets/social-media/facebook.svg"
import Instagram from "public/assets/social-media/instagram.svg"
import Twitter from "public/assets/social-media/twitter-x.svg"
import TikTok from "public/assets/social-media/tiktok.svg"
import Youtube from "public/assets/social-media/youtube.svg"
import LinkedIn from "public/assets/social-media/linkedin.svg"

export default function Footer({ footerMenu, footerContent, phoneNumbers, socialMedia }) {
  function SocialLink({ label }) {
    const Icons = {
      Facebook: <Facebook />,
      YouTube: <Youtube />,
      Twitter: <Twitter />,
      TikTok: <TikTok />,
      Instagram: <Instagram />,
      LinkedIn: <LinkedIn />,
    }
    const Icon = () => Icons?.[label] ?? null

    return (
      <Link href={socialMedia[label]} target="_blank" title={label}>
        <Icon />
      </Link>
    )
  }

  return (
    <footer className="bg-blueWaveOverlay bg-cover bg-no-repeat bg-left-top py-10 px-5">
      <div className="max-w-screen-xl mx-auto grid lg:grid-cols-5 pb-5 md:pb-10 border-b-2 border-b-secondary">
        <div className="lg:col-span-2">
          <Logo className="mb-4" />
          <div className="flex flex-row lg:flex-col gap-4 mb-4">
            <CallToAction href={getTelLink(phoneNumbers?.Main)} style="phone" className="whitespace-nowrap">
              {phoneNumbers?.Main}
            </CallToAction>
            <CallToAction href="/free-case-evaluation" style="chat">
              Free Case Review
            </CallToAction>
          </div>
          <div className="flex gap-5 items-center mb-6 lg:mb-0 justify-center sm:justify-start">
            <SocialLink label="Facebook" />
            <SocialLink label="Twitter" />
            <SocialLink label="Instagram" />
            <SocialLink label="YouTube" />
            <SocialLink label="TikTok" />
            <SocialLink label="LinkedIn" />
          </div>
        </div>
        <div className="grid md:flex justify-between grid-cols-2 lg:col-span-3 gap-x-3 gap-y-5">
          {footerMenu.map((menu) => (
            <div key={menu.label} className="">
              <h2 className="text-m1 text-white font-semibold font-primary pb-2.5">{menu.label}</h2>
              <ul>
                {menu.nested_menu_items.map((item) => {
                  const href = getStoryblokLink(item.link)
                  return (
                    <li key={item.label} className="pb-2.5 last:pb-0">
                      <Link
                        href={href}
                        target={getTarget(href)}
                        className="font-secondary font-normal text-white underline hover:no-underline"
                      >
                        {item.label}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <div className="max-w-screen-xl mx-auto pt-5 md:pt-10">
        <div className="prose-p:text-white prose-p:text-s1 pb-8">{richText(footerContent)}</div>
        <div className="flex justify-between flex-col lg:flex-row gap-4">
          <div className="text-white text-s1 font-secondary">© The Husband & Wife Law Team | All rights reserved.</div>
          <div className="flex items-center gap-2 mx-auto lg:mx-0">
            <Link href="/privacy-policy" className="font-secondary font-normal text-white underline hover:no-underline">
              Privacy Policy
            </Link>
            <span className="text-white font-secondary">{"|"}</span>{" "}
            <Link href="/accessibility" className="font-secondary font-normal text-white underline hover:no-underline">
              Accessibility
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
}
